import React from 'react'
import { Helmet } from 'react-helmet';
import { Link } from 'gatsby';
import GlobalLoader from '../../components/GlobalLoader';

const LayoutStatic = ({ children }) => {
  return (
    <>
      <GlobalLoader />
      <Helmet>
        <meta charset="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
        <title>Mor Online</title>

        <script src="https://cdn.jsdelivr.net/npm/popper.js@1.16.0/dist/umd/popper.min.js" integrity="sha384-Q6E9RHvbIyZFJoft+2mJbHaEWldlvI9IOYy5n3zV9zzTtmI3UksdQRVvoxMfooAo" crossOrigin="anonymous" defer></script>
        <script src="https://stackpath.bootstrapcdn.com/bootstrap/4.4.1/js/bootstrap.min.js" integrity="sha384-wfSDF2E50Y2D1uUdj0O3uMBJnjuUD4Ih7YwaYd1iqfktj0Uod8GCExl3Og8ifwB6" crossOrigin="anonymous" defer></script>
        <link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/4.4.1/css/bootstrap.min.css" integrity="sha384-Vkoo8x4CGsO3+Hhxv8T/Q5PaXtkKtu6ug5TOeNV6gBiFeWPGFN9MuhOf23Q9Ifjh" crossOrigin="anonymous" />
      </Helmet>
      <div>
        {/* <nav className="navbar navbar-expand-lg navbar-light bg-light">
          <Link className="navbar-brand" to="/">
            <img src="/img/LogoMorLine.png" width="130" height="30" alt="" />
          </Link>
          <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarNavAltMarkup">
            <div className="navbar-nav">
            <Link className="nav-item nav-link active" to="/menu/CheckUp">Home Service ตรวจสุขภาพที่บ้าน <span className="sr-only">(current)</span></Link>
              <Link className="nav-item nav-link active" to="/menu/infant">พัฒนาการทารก(อายุ 0-11 เดือน) <span className="sr-only">(current)</span></Link>
              <Link className="nav-item nav-link active" to="/menu/child">พัฒนาการเด็ก(อายุ 1-8 ปี)<span className="sr-only">(current)</span></Link>
              <Link className="nav-item nav-link active" to="/menu/finemotorNwriting">พัฒนาการเด็ก(กล้ามเนื้อมัดเล็กและการเขียน)<span className="sr-only">(current)</span></Link>
              <Link className="nav-item nav-link active" to="/menu/ChildInsocial">พัฒนาการเด็ก(การเข้าสังคมและการช่วยเหลือตัวเอง)<span className="sr-only">(current)</span></Link>
              <Link className="nav-item nav-link active" to="/menu/mental">สุขภาพจิต <span className="sr-only">(current)</span></Link>
              <Link className="nav-item nav-link active" to="/menu/ncd">โรคทั่วไป <span className="sr-only">(current)</span></Link>
              <Link className="nav-item nav-link active" to="/menu/old">ผู้สูงอายุ <span className="sr-only">(current)</span></Link>
            </div>
          </div>
        </nav> */}
        {children}
      </div>
    </>
  );
}

export default LayoutStatic

